import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Alert } from './components/Alert';
import { YouTube } from './components/YouTube';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "organize-tabs"
    }}>{`Organize Tabs`}</h1>
    <hr></hr>
    <p>{`To organize Tabs for your mobile app, follow these step-by-step instructions:`}</p>
    <ol>
      <li parentName="ol">{`Access the Content Management System and navigate to the Mobile Apps section from the left side menu options. Click on the Mobile App where you want to organize the tabs. `}</li>
      <li parentName="ol">{`Select the tab you wish to organize from the Tabs Manager.`}</li>
      <li parentName="ol">{`Drag the tab to it's new location. A dashed line between tabs indicates where the tab will be placed in the tab structure. `}</li>
      <li parentName="ol">{`Click 'Save' at the bottom of the page to update your app to display your new tab order.  `}</li>
    </ol>
    <p><img alt="CMS Organize Tab" src={require("./images/cms_organize_tab.gif")} />{` `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      